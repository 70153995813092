<template>
  <div class="mapbox-gl-control incident-toggle"
       :style="[offsetStyle, posStyle]">
    <!-- Button -->
    <v-tooltip :left="attachLeft" :right="!attachLeft">
      <template #activator="{ on: tooltip }">
        <v-btn class="pa-1 ma-2"
               height="auto"
               min-width="0"
               :color="mapToggle ? 'primary darken-1' : 'white'"
               small
               v-on="{ ...tooltip }"
               @click="buttonClick">
          <v-icon>local_fire_department</v-icon>
        </v-btn>
      </template>
      <span>
        <slot name="tooltip">Toggle incidents</slot>
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import mapboxLazyMixin from '@/utils/mixins/mapbox/mapboxLazy.mixin.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'pibot-mapbox-incident-toggle',
  mixins: [positionStyleMixin, offsetStyleMixin, mapboxBasicsMixin, mapboxLazyMixin],
  computed: {
    ...mapState({
      mapToggle: state => state.eprs.mapToggle
    })
  },
  methods: {
    ...mapMutations({
      setMapToggle: 'eprs/SET_MAP_TOGGLE'
    }),
    buttonClick () {
      // this.active = !this.active
      this.setMapToggle(!this.mapToggle)
      // get all features
      // Handle lazy-loading
      this.lazyLoadSource('eprs-incidents')
        .then(() => {
          if (this.map) this.setLayerVisibility('eprs-incidents', this.mapToggle)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}

</script>
